<template>
  <div class="home-user mt-2">
    <!-- Analytics -->

    <p align="right">
      If you have problems loading the page, please disable adblock!
    </p>

    <b-alert
      v-if="isBeforeTargetDate && alertNotDismissed"
      show
      dismissible
      variant="success"
      class="text-center"
      @dismissed="dismissAlert"
      ><br />
      <b>INFO:</b> &rArr; REVOLUT instant payment now available!
      <br />
      <br />
    </b-alert>

    <AnalyticsComponet :info="info" />
    <!-- Analaysis -->
    <AnalaysisComponet :info="info" />
  </div>
</template>

<script>
import { BAlert } from "bootstrap-vue";

import info from "./data/main.fetch";
import AnalyticsComponet from "./Analytics";
import AnalaysisComponet from "./Analaysis";
export default {
  data: () => ({
    contact: "imeicheck20@gmail.com",
    info,
    // Combine into a single data method
    alertNotDismissed: !localStorage.getItem("alertDismissed"),
  }),
  methods: {
    async fetchData() {
      Object.values(this.info).forEach(async (inf) => {
        inf.loading = true;
        inf.value = await inf.fetch();
        inf.loading = false;
      });
    },
    dismissAlert() {
      localStorage.setItem("alertDismissed", "true");
      this.alertNotDismissed = false;
    },
  },
  mounted() {
    this.fetchData();
  },
  components: {
    AnalyticsComponet,
    AnalaysisComponet,
    "b-alert": BAlert,
  },

  computed: {
    // Computed property to determine if the current date is before the target date
    isBeforeTargetDate() {
      const currentDate = new Date();
      const targetDate = new Date("2024-05-01T00:00:00"); // Target date set to 01.05.2024
      return currentDate < targetDate;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
.content-api {
  padding: 0 15px;
  margin-top: 8px;
  li {
    font-weight: bold;
  }
}
@media (max-width: 991px) {
  .home-user {
    .row {
      > * {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
